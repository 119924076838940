import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "article_card mb-4" }
const _hoisted_2 = { class: "article_img" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "article-cont" }
const _hoisted_6 = ["href"]
const _hoisted_7 = {
  key: 0,
  class: "badge mb-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        target: "_blank",
        href: _ctx.link,
        ref: "noopener nofollow"
      }, [
        _createElementVNode("img", {
          src: _ctx.validImage ? _ctx.thumbnail : _ctx.imagePlaceholder,
          alt: "",
          class: "img-fluid",
          onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLoadImage && _ctx.onLoadImage(...args))),
          onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLoadImageError && _ctx.onLoadImageError(...args)))
        }, null, 40, _hoisted_4)
      ], 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("a", {
        href: _ctx.link,
        target: "_blank",
        ref: "noopener nofollow"
      }, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1)
      ], 8, _hoisted_6),
      (_ctx.isNews)
        ? (_openBlock(), _createElementBlock("span", _hoisted_7, "News"))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, _toDisplayString(_ctx.snippet), 1)
    ])
  ]))
}