
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    image: String,
    link: String,
    title: String,
    source: String,
  },
  data() {
    return {
      isLoaded: true,
    };
  },
  methods: {
    onError(e) {
      this.isLoaded = false;
    },
  },
});
