import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "accordian_outer" }
const _hoisted_2 = {
  id: "accordion",
  class: "cmn-accord-wrap"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_question_to_validate = _resolveComponent("question-to-validate")!
  const _component_question_card = _resolveComponent("question-card")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_question_to_validate, {
            key: 0,
            topicId: _ctx.topicId
          }, null, 8, ["topicId"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.displayedQuestions, (question, index) => {
          return (_openBlock(), _createBlock(_component_question_card, {
            key: question.id,
            question: question,
            label: question.label,
            topicId: _ctx.topicId,
            onOnDelete: ($event: any) => (_ctx.deleteQuestion(index)),
            onOnShowAuthModal: _ctx.onShowAuthModal
          }, null, 8, ["question", "label", "topicId", "onOnDelete", "onOnShowAuthModal"]))
        }), 128))
      ])
    ]),
    _createVNode(_component_pagination, {
      currentPage: _ctx.currentPage,
      totalPages: _ctx.totalPages,
      onOnChangePage: _ctx.setPage
    }, null, 8, ["currentPage", "totalPages", "onOnChangePage"])
  ], 64))
}