
import { SearchKeyword } from "@toppick/common/build/interfaces";
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";

export default defineComponent({
  emits: ["onChangeKeyword"],
  props: {
    keywords: {
      type: Array as PropType<SearchKeyword[]>,
      required: true,
    },
    activeKeyword: {
      type: Object as PropType<SearchKeyword>,
      required: false,
    },
  },
  methods: {
    isActive(id: number) {
      return this.activeKeyword && this.activeKeyword.id == id;
    },
    onChangeKeyword(index: number) {
      this.$emit("onChangeKeyword", index);
    },
  },
});
