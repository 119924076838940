
import { defineComponent } from "vue";
import { Question } from "@/interfaces/topics";
import { ValidationAction } from "@toppick/common/build/interfaces";
import "../styles.css";
import GuideToolTip from "@/components/topic/GuideToolTip.vue";
import {
  validateQuestion,
  getQuestionToValidate,
} from "@toppick/common/build/api";
import UserAvatar from "@/components/ui/UserAvatar.vue";
import { getAuthToken } from "@/utils/auth";

export default defineComponent({
  props: {
    topicId: {
      type: Number,
      required: true,
    },
  },
  components: { GuideToolTip, UserAvatar },
  data() {
    return {
      isShowValidate: false,
      isShowValidateShow: true,
      isShowValidateHide: true,
      isLoading: true,
      isPageLoaded: false,
      ValidationAction,
      question: null as null | Question,
    };
  },
  methods: {
    async onValidate(action: ValidationAction) {
      this.isLoading = true;
      try {
        await validateQuestion(await getAuthToken(), this.question!.id, action);
        await this.loadData();
      } catch (error) {
        console.log(error);
      }
      this.isLoading = false;
    },
    async loadData() {
      if (this.$store.getters.getIsAuthenticated) {
        this.question = await getQuestionToValidate(await getAuthToken(), {
          topic_id: this.topicId,
        });
        this.isShowValidate = true;
      }
    },
    toggleIsShowValidate() {
      this.isShowValidateShow = !this.isShowValidateShow;
      this.isShowValidateHide = !this.isShowValidateHide;
      this.isShowValidate = !this.isShowValidate;
    },
  },
  computed: {
    show() {
      return this.isLoading || this.question;
    },
  },
  async mounted() {
    try {
      await this.loadData();
    } catch (error) {
      console.log(error);
    }
    this.isLoading = false;
    this.isPageLoaded = true;
  },
});
