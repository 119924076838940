
import "../ui/styles.css";
export default {
  props: {
    quote: String,
    url: String,
    hashtags: String,
    title: String,
    description: String,
  },
};
