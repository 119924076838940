
import { defineComponent, PropType } from "vue";
import { v4 as uuidv4 } from "uuid";
import GuideToolTip from "@/components/topic/GuideToolTip.vue";
import { ActionStatus } from "@/interfaces/ui";
import QuestionMenu from "./question/QuestionMenu.vue";
import {
  deleteQuestion,
  getQuestionDetails,
  updateQuestion,
} from "@toppick/common/build/api/question";
import { Question, QuestionFeatured } from "@/interfaces/topics";
import StatusBadge from "@/components/ui/badges/StatusBadge.vue";
import AnswerSection from "@/components/question/answer/AnswerSection.vue";
import ResourceSection from "@/components/question/resource/ResourceSection.vue";
import { getInitials } from "@/utils/ui";
import EditInput from "@/components/question/EditInput.vue";
import {
  validateQuestionTitleMin,
  validateQuestionTitleMax,
} from "@toppick/common/build/validators";
import { getErrorMessage } from "@toppick/common/build/utils";
import { getAuthToken } from "@/utils/auth";

const PRELOADED_ANSWER_COUNT = 3;
const PRELOADED_RESOURCE_COUNT = 3;

export default defineComponent({
  emits: ["onDelete", "onShowAuthModal"],
  components: {
    GuideToolTip,
    QuestionMenu,
    StatusBadge,
    AnswerSection,
    ResourceSection,
    EditInput,
  },
  props: {
    question: {
      type: Object as PropType<QuestionFeatured>,
      required: true,
    },
    topicId: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      undoIcon: require("@/assets/images/undo.svg"),
      questionHeadingIcon: require("@/assets/images/question-heading-icon.svg"),
      copyIcon: require("@/assets/images/copy-icon.svg"),
      currentQuestion: { ...this.question } as QuestionFeatured | Question,
      previousQuestion: { ...this.question } as QuestionFeatured | Question,
      isOpen: false,
      copyModal: false,
      isShowMenu: false,
      isEditing: false,
      isDeleting: false,
      error: "",
      isLoading: false,
      isShowReport: false,
      isQuestionLoading: false,
      isShowTranslation: false,
      isTranslate: false,
      questionTextInputId: uuidv4(),
    };
  },
  methods: {
    async toggleCard() {
      if (this.isEditing) return;
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.isLoading = true;
        try {
          this.currentQuestion = await getQuestionDetails(
            await getAuthToken(),
            {
              id: this.currentQuestion.id,
              answer_count: PRELOADED_ANSWER_COUNT,
              resource_count: PRELOADED_RESOURCE_COUNT,
              include_answers: true,
              include_resources: true,
              include_resource_validate: true,
              include_answer_validate: true,
              include_topic: false,
            }
          );
        } catch (error) {
          console.log(error);
        }
        this.isLoading = false;
      }
    },
    toggleIsShowTranslation() {
      this.isShowTranslation = !this.isShowTranslation;
    },
    onCloseQuestionEditField() {
      this.isEditing = false;
      this.isLoading = false;
      this.error = "";
      this.currentQuestion.title = this.previousQuestion.title;
    },
    onEdit() {
      this.isEditing = true;
    },

    async onUpdateQuestion() {
      this.error = "";
      this.isQuestionLoading = true;
      try {
        if (!validateQuestionTitleMin(this.currentQuestion.title)) {
          throw new Error("Question should be longer");
        }

        if (!validateQuestionTitleMax(this.currentQuestion.title)) {
          throw new Error("Question should be shorter");
        }

        const question = await updateQuestion(
          await getAuthToken(),
          this.currentQuestion.id,
          {
            title: this.currentQuestion.title,
            topic_id: this.topicId,
          }
        );

        this.currentQuestion.action_status = ActionStatus.EDITED;
        this.currentQuestion.status = question.status!;
        this.previousQuestion = { ...this.currentQuestion };
        this.isEditing = false;
      } catch (err) {
        this.error = getErrorMessage(err);
      }
      this.isQuestionLoading = false;
    },

    async onCopy() {
      try {
        const el = document.getElementById(this.questionTextInputId);
        if (!el) return;
        await navigator.clipboard.writeText(el.textContent!);
        this.copyModal = true;
        setTimeout(() => {
          this.copyModal = false;
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },
    toggleMenu() {
      this.isShowMenu = !this.isShowMenu;
    },
    showReport() {
      this.isShowReport = true;
      setTimeout(() => {
        this.isShowReport = false;
      }, 3000);
    },

    onDeleteQuestion() {
      this.currentQuestion.action_status = ActionStatus.DELETING_EFF_1;
      setTimeout(() => {
        this.currentQuestion.action_status = ActionStatus.DELETING_EFF_2;
      }, 3000);
      setTimeout(async () => {
        if (
          this.currentQuestion.action_status === ActionStatus.DELETING_EFF_2
        ) {
          await deleteQuestion(await getAuthToken(), this.currentQuestion.id);
          this.$emit("onDelete");
        }
      }, 6000);
    },
    resetDelete() {
      this.currentQuestion.action_status = ActionStatus.DEFAULT;
    },
    onTranslate({ isTranslate }: { isTranslate: boolean }) {
      this.isTranslate = isTranslate;
      this.isShowTranslation = isTranslate;
    },
  },

  computed: {
    cardBackground(): string {
      switch (this.currentQuestion.action_status) {
        case ActionStatus.ADDED:
          return "card-highlight-soft";
        case ActionStatus.DELETING_EFF_1:
          return "red-bg";
        case ActionStatus.DELETING_EFF_2:
          return "delete-collapse";
        default:
          return "";
      }
    },
    backgroundClass(): string {
      switch (this.currentQuestion.action_status) {
        case ActionStatus.ADDED:
          return "card-highlight-soft";
        case ActionStatus.EDITED:
          return "card-highlight-soft";
        default:
          return "";
      }
    },
    initials() {
      if (this.currentQuestion.users)
        return getInitials(this.currentQuestion.users.username);
      else return "";
    },
    showUndoButton(): boolean {
      return this.currentQuestion.action_status === ActionStatus.DELETING_EFF_2;
    },
    currentUser() {
      return this.$store.getters.getUserFeatured;
    },
  },

  mounted() {
    this.$watch(
      () => ({
        isTranslate: this.$store.getters.isTranslateContent,
      }),
      ({ isTranslate }) => {
        this.isTranslate = isTranslate;
        setTimeout(() => {
          this.isShowTranslation = isTranslate;
        }, 500);
      },
      { immediate: true }
    );
  },
});
