
import { defineComponent, PropType } from "vue";
import { SideBarItem } from "@/interfaces/ui";
import "./styles.css";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<SideBarItem[]>,
      required: true,
    },
    title: String,
    type: String,
    maxItems: Number,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    onClick(slug: string) {
      this.$emit("onClick", slug);
    },
    toggleMenu() {
      this.isOpen = !this.isOpen;
    },
  },
});
