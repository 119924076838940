
import { PropType } from "vue";
import { defineComponent } from "@vue/runtime-core";
import { ValidationStatus } from "@toppick/common/build/interfaces";
import { hasPriviledgedRole } from "@/utils/auth";

export default defineComponent({
  props: {
    status: {
      type: String as PropType<ValidationStatus>,
      required: true,
    },
    user_id: {
      type: String,
      required: true,
    },
  },
  computed: {
    badgeText() {
      switch (this.status) {
        case ValidationStatus.Pending:
          return "Validating";
        case ValidationStatus.Rejected:
          return "Rejected";
        case ValidationStatus.Active:
          return "Confirmed";
        default:
          return "";
      }
    },
    tooltipText() {
      switch (this.status) {
        case ValidationStatus.Pending:
          return `This question is still being validated`;
        case ValidationStatus.Rejected:
          return `This question has been rejected`;
        case ValidationStatus.Active:
          return `This question has been confirmed`;
        default:
          return "";
      }
    },
    backgroundClass(): string {
      switch (this.status) {
        case ValidationStatus.Pending:
          return "bg-primary";
        case ValidationStatus.Rejected:
          return "bg-danger";
        case ValidationStatus.Active:
          return "bg-success";
        default:
          return "";
      }
    },

    show(): boolean {
      return (
        !hasPriviledgedRole(this.$store.getters.getUserRole) &&
        this.user_id === this.$store.getters.getUserID
      );
    },
  },
});
