
import { defineComponent } from "vue";
import QuestionCard from "@/components/question/QuestionCard.vue";
import { getQuestions } from "@toppick/common/build/api/question";
import Pagination from "@/components/ui/Pagination.vue";
import { ActionStatus } from "@/interfaces/ui";
import { countPages, parsePage } from "@/utils/ui";
import { Question, QuestionFeatured } from "@/interfaces/topics";
import QuestionToValidate from "@/components/question/question/QuestionToValidate.vue";
import { getAuthToken, hasPriviledgedRole } from "@/utils/auth";
import "./styles.css";

const RESULTS_PER_PAGE = 10;

interface QuestionDisplayed extends QuestionFeatured {
  label: string;
}

export default defineComponent({
  emits: ["onShowAuthModal"],
  components: {
    QuestionCard,
    Pagination,
    QuestionToValidate,
  },
  data() {
    return {
      isLoading: true,
      questions: [] as QuestionFeatured[],
      isShowValidate: true,
      newQuestionsCounter: 0,
    };
  },
  props: {
    topicId: {
      type: Number,
      required: true,
    },
    questionCount: {
      type: Number,
      required: true,
    },
  },

  methods: {
    async loadData() {
      this.isLoading = true;
      try {
        const skip = (this.currentPage - 1) * RESULTS_PER_PAGE;
        this.questions = await getQuestions(await getAuthToken(), {
          topic_id: this.topicId,
          limit: RESULTS_PER_PAGE,
          skip,
        });
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
    onShowAuthModal() {
      this.$emit("onShowAuthModal");
    },
    setPage(page: number) {
      this.newQuestionsCounter = 0;
      this.$router.replace({
        query: { page },
      });
      window.scrollTo({
        top: 200,
        behavior: "smooth",
      });
    },

    deleteQuestion(index: number) {
      this.questions.splice(index, 1);
    },

    onQuestionAdd({ question }: { question: Question }) {
      question.action_status = ActionStatus.ADDED;
      this.questions = [question, ...this.questions];
      this.newQuestionsCounter++;
    },
  },
  computed: {
    totalPages() {
      return countPages(this.questionCount, RESULTS_PER_PAGE);
    },
    resultPerPage() {
      return RESULTS_PER_PAGE;
    },
    currentPage(): number {
      return parsePage(this.$route.query.page);
    },
    displayedQuestions(): QuestionDisplayed[] {
      let index = (this.currentPage - 1) * this.resultPerPage;
      let submittedIndex = 0;
      const displayedQuestions: QuestionDisplayed[] = (
        this.questions as QuestionDisplayed[]
      ).map((question) => {
        if (question.action_status === ActionStatus.ADDED) {
          question.label = "Submitted Just Now";
          return question;
        } else if (
          !hasPriviledgedRole(this.$store.getters.getUserRole) &&
          question.user_id === this.$store.getters.getUserID
        ) {
          submittedIndex++;
          question.label = `Submitted Question ${submittedIndex}`;
          return question;
        } else {
          index++;
          question.label = `Question ${index}`;
          return question;
        }
      });
      return displayedQuestions;
    },
  },
  async mounted() {
    this.$watch(
      () => ({
        topicId: this.topicId,
        page: this.currentPage,
      }),
      async () => {
        try {
          await this.loadData();
        } catch (error) {
          console.log(error);
        }
      },
      { immediate: true }
    );

    this.eventBus.on("onQuestionAdd", this.onQuestionAdd);
  },
});
