
import { PropType } from "vue";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    title: String,
    link: String,
    thumbnail: String,
    snippet: String,
    duration: String,
    displayed_link: String,
    isNews: Boolean,
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  methods: {
    loadImage() {
      this.isLoaded = true;
    },
  },
});
