
import ListModal from "@/components/my_topic/ListModal.vue";
import QuestionSubmitInput from "@/components/question/question/QuestionSubmitInput.vue";
import { Question } from "@toppick/common/build/interfaces";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  components: { QuestionSubmitInput, ListModal },
  emits: ["onShowAuthModal", "onQuestionCreate"],
  props: {
    topic_id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isShowMenu: false,
      isShowAddQuestion: false,
      isShowListModal: false,
    };
  },
  methods: {
    toggleShowAddQuestion() {
      if (!this.$store.getters.getIsAuthenticated) {
        this.$emit("onShowAuthModal");
      } else {
        this.isShowAddQuestion = !this.isShowAddQuestion;
      }
      this.setShowMenu(false);
    },
    toggleShowListModal() {
      if (!this.$store.getters.getIsAuthenticated) {
        this.$emit("onShowAuthModal");
      } else {
        this.isShowListModal = !this.isShowListModal;
      }
      this.setShowMenu(false);
    },

    setShowMenu(value: boolean) {
      this.isShowMenu = value;
    },
    onQuestionCreate(question: Question) {
      this.$emit("onQuestionCreate", question);
      this.toggleShowAddQuestion();
    },
  },
});
