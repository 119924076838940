
import { defineComponent, PropType } from "vue";
import {
  SearchKeyword,
  SearchResultImage,
} from "@toppick/common/build/interfaces";
import { getSearchResultsImage } from "@toppick/common/build/api/search";
import Pagination from "@/components/ui/Pagination.vue";
import SearchImageCard from "@/components/topic/SearchImageCard.vue";
import SearchKeywords from "@/components/topic/SearchKeywords.vue";
import { getAuthToken } from "@/utils/auth";

export default defineComponent({
  props: {
    topicId: {
      type: Number,
      required: true,
    },
    keywords: {
      type: Array as PropType<SearchKeyword[]>,
      required: true,
    },
  },
  components: {
    Pagination,
    SearchImageCard,
    SearchKeywords,
  },
  data() {
    return {
      isLoading: true,
      images: [] as SearchResultImage[],
    };
  },

  methods: {
    async loadData() {
      this.isLoading = true;
      this.images = await getSearchResultsImage(await getAuthToken(), {
        keyword_id: this.currentKeyword!.id,
      });
      this.isLoading = false;
    },
    onChangeKeyword(index: number) {
      this.$router.replace({
        query: { keyword: this.keywords[index].title },
      });
    },
  },

  computed: {
    totalPages() {
      return this.keywords.length;
    },
    currentPage() {
      if (!this.currentKeyword) return 1;
      return (
        this.keywords.findIndex((k) => k.id === this.currentKeyword!.id) + 1
      );
    },
    currentKeyword(): SearchKeyword | null {
      if (this.keywords.length <= 0) return null;
      return (
        this.keywords.find((k) => k.title === this.$route.query.keyword) ||
        this.keywords[0]
      );
    },
  },

  async mounted() {
    this.$watch(
      () => ({
        keyword: this.currentKeyword,
      }),
      async (keyword) => {
        try {
          await this.loadData();
        } catch (error) {
          console.log(error);
        }
      },
      { immediate: true }
    );
  },
});
