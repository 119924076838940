import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_submit_input = _resolveComponent("submit-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["add_input_area", { show: _ctx.show }])
  }, [
    _createVNode(_component_submit_input, {
      error: _ctx.error,
      placeholder: "Submit a new question",
      onOnSubmit: _ctx.onSubmitQuestion,
      onOnClose: _ctx.onClose,
      modelValue: _ctx.title,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.title) = $event)),
      user: _ctx.currentUser,
      isLoading: _ctx.isLoading
    }, null, 8, ["error", "onOnSubmit", "onOnClose", "modelValue", "user", "isLoading"])
  ], 2))
}