
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  props: {
    title: String,
    link: String,
    thumbnail: String,
    snippet: String,
    isNews: Boolean,
  },
  data() {
    return {
      isImageLoaded: false,
      isImageError: false,
      imagePlaceholder: require("@/assets/images/search_img_placeholder.jpg"),
    };
  },
  methods: {
    onLoadImage() {
      this.isImageLoaded = true;
    },
    onLoadImageError() {
      this.isImageError = true;
    },
  },
  computed: {
    validImage() {
      return this.isImageLoaded && !this.isImageError;
    },
  },
});
