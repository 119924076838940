import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "blog_keywords mb-4" }
const _hoisted_2 = { class: "keywords" }
const _hoisted_3 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.keywords, (keyword, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: keyword.id
          }, [
            _createElementVNode("button", {
              onClick: ($event: any) => (_ctx.onChangeKeyword(index)),
              class: _normalizeClass({ active: _ctx.isActive(keyword.id) })
            }, [
              _createElementVNode("span", null, _toDisplayString(keyword.title), 1)
            ], 10, _hoisted_3)
          ]))
        }), 128))
      ])
    ])
  ]))
}