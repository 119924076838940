
import { defineComponent } from "@vue/runtime-core";
import { getAuthToken, hasEditPermission } from "@/utils/auth";
import { ReportReason } from "@toppick/common/build/interfaces";
import { reportQuestion } from "@toppick/common/build/api/report";
export default defineComponent({
  emits: ["onEdit", "onDelete", "showReport"],
  props: {
    userId: {
      required: true,
      type: String,
    },
    question_id: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      isMenuOpen: false,
      isReportOpen: false,
      ReportReason,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

    toggleReport() {
      this.isReportOpen = !this.isReportOpen;
    },

    onEdit() {
      this.toggleMenu();
      this.$emit("onEdit");
    },
    onDelete() {
      this.toggleMenu();
      this.$emit("onDelete");
    },
    async onReport(reason: ReportReason) {
      try {
        this.toggleMenu();
        await reportQuestion(await getAuthToken(), this.question_id, reason);
        this.$emit("showReport");
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    hasEditPermission() {
      return hasEditPermission(
        this.$store.getters.getUserID,
        this.userId,
        this.$store.getters.getUserRole
      );
    },
  },
});
