import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["href"]
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "image_name" }
const _hoisted_4 = ["href"]
const _hoisted_5 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return _withDirectives((_openBlock(), _createElementBlock("li", null, [
    _createElementVNode("a", {
      href: _ctx.link,
      target: "_blank",
      ref: "noopener nofollow"
    }, [
      _createElementVNode("img", {
        src: _ctx.image,
        alt: _ctx.title,
        onLoad: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.loadImage && _ctx.loadImage(...args))),
        onError: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onError && _ctx.onError(...args)))
      }, null, 40, _hoisted_2)
    ], 8, _hoisted_1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("a", {
        target: "_blank",
        href: _ctx.link,
        ref: "noopener nofollow"
      }, _toDisplayString(_ctx.title), 9, _hoisted_4),
      _createElementVNode("a", {
        target: "_blank",
        href: _ctx.link,
        class: "image_link",
        ref: "noopener nofollow"
      }, _toDisplayString(_ctx.source), 9, _hoisted_5)
    ])
  ], 512)), [
    [_vShow, _ctx.isLoaded]
  ])
}