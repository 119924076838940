
import { defineComponent } from "@vue/runtime-core";
import { createQuestion } from "@toppick/common/build/api/question";
import SubmitInput from "../SubmitInput.vue";
import {
  validateQuestionTitleMin,
  validateQuestionTitleMax,
} from "@toppick/common/build/validators";
import { getErrorMessage } from "@toppick/common/build/utils";
import { getAuthToken } from "@/utils/auth";
export default defineComponent({
  emits: ["onQuestionCreate", "onQuestionClose"],
  props: {
    topic_id: {
      type: Number,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      title: "",
      error: "",
      isLoading: false,
    };
  },
  components: { SubmitInput },
  methods: {
    async onSubmitQuestion() {
      this.isLoading = true;
      this.error = "";
      try {
        if (!validateQuestionTitleMin(this.title)) {
          throw new Error("Question should be longer");
        }

        if (!validateQuestionTitleMax(this.title)) {
          throw new Error("Question should be shorter");
        }
        const questionCreated = await createQuestion(await getAuthToken(), {
          title: this.title,
          topic_id: this.topic_id,
        });
        this.$emit("onQuestionCreate", questionCreated);
        this.title = "";
        this.error = "";
      } catch (err) {
        this.error = getErrorMessage(err);
      }
      this.isLoading = false;
    },
    onClose() {
      this.$emit("onQuestionClose");
      this.error = "";
      this.title = "";
      this.isLoading = false;
    },
  },
  computed: {
    currentUser() {
      return this.$store.getters.getUserFeatured;
    },
  },
});
