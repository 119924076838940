
import { PropType, defineComponent } from "vue";
import { TopicLevel } from "@toppick/common/build/interfaces";
import { getTopicLevelLabel } from "@/utils/topics";
import SelectTranslate from "@/components/ui/SelectTranslate.vue";

export default defineComponent({
  components: { SelectTranslate },
  props: {
    title: String,
    source: String,
    level: {
      type: String as PropType<TopicLevel>,
      required: true,
    },
  },
  computed: {
    levelLabel() {
      return getTopicLevelLabel(this.level);
    },
    topicLevelBadge() {
      switch (this.level) {
        case TopicLevel.Easy:
          return require("@/assets/images/level-easy.svg");
        case TopicLevel.Medium:
          return require("@/assets/images/level-medium.svg");
        case TopicLevel.Hard:
          return require("@/assets/images/level-hard.svg");
        default:
          return require("@/assets/images/level-hard.svg");
      }
    },
  },
});
